import request from '@/utils/request'

/**
 * 沉默客户sop
 */
export class SilenceSopApi {
  // 沉默客户sop列表
  static index (params) {
    return request({
      url: '/silenceSop/logIndex',
      method: 'get',
      params
    })
  }
}