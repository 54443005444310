<template>
  <div class="index">
    <van-nav-bar
      title="沉默客户SOP"
      left-text="返回"
      left-arrow
      fixed
      placeholder
      @click-left="onClickLeft"
    />
    <!-- <div class="title-serch">
      <van-search
          style="flex: 1;"
          v-model="serchValue"
          show-action
          placeholder="输入群发账号"
          shape="round"
          @search="onSearch"
          @clear="clearSerch"
      >
        <template #action >
          <div @click="routerPush('/contactMessageBatchSend/create')" style="    display: flex;
    align-items: center; color:#2b60dd;    font-size: 26px;">
            <van-icon style="font-size: 26px" name="search" />
          </div>
        </template>
      </van-search>
    </div> -->
    <div class="content">
      <van-list
        v-model="loading"
        :finished="finished"
        @load="onLoad"
      >
        <div class="list-item" v-for="(item, index) in list" :key="index">
          <div class="list-item-time">
              <div>
                <img style="margin-right: 8px; width: 18px" src="../../assets/images/clock.png"> 提醒时间：{{ item.notice_time }}
              </div>
            </div>
            <div class="list-item-user">
              <van-image
                radius="6"
                width="40"
                height="40"
                :src="item.contact.avatar"
              />
              <div>
                {{ item.contact.name }}
                <div>最后跟进：{{ item.last_follow_time }}</div>  
              </div>
              <div class="hand">
                <van-button size="mini" @click="pushUser(item.contact.id)">跟进</van-button>
              </div> 
            </div>
        </div>
        <div v-if="finished && list.length" slot="finished">无更多数据</div>
      </van-list>
      <van-empty
        v-if="finished && !list.length"
        class="custom-image"
        :image="require('../../assets/images/search-no-data.svg')"
        description="暂无数据"
      />
    </div>
  </div>
</template>
<script>
import { getCookie, pushUser } from '@/plugins/utils'
import { SilenceSopApi } from '@/api/silenceSop'
export default {
  data() {
    return {
      pushUser,
      serchValue: '',
      loading: false,
      finished: false,
      list: [],
      page: 1,
      perPage: 15
    }
  },
  created () {
    window.scrollTo(0, 0)
  },
  methods: {
    // 返回上一页
    onClickLeft() {
      this.$router.back()
    },
    onLoad () {
      this.loading = true
      SilenceSopApi.index({
        page: this.page,
        page_size: this.perPage
      }).then(res => {
        this.loading = false
        if(res.data.data.length < this.perPage){
          this.finished = true
          this.loading = false
        }else{
          this.page++
          this.finished = false
          this.loading = false
        }
        this.list = this.list.concat(res.data.data || [])
      })
    },
    naireDetails (item) {
      console.log(item)
      this.$router.push('/contact/screen', {})
    },
    onSearch () {},
    clearSerch () {},
    // 路由跳转
    routerPush(e) {
      this.$router.push(e)
    },
  }
}
</script>
<style scoped lang="less">
.index {
  font-size: 14px;
  height: 100%;
  background: #f4f5f7;

  /deep/ .van-nav-bar__content {
    background: #2b63cd;
    color: #fff;
  }

  /deep/ .van-nav-bar .van-icon {
    color: #fff;
  }

  /deep/ .van-nav-bar__text {
    color: #fff;
  }

  /deep/ .van-nav-bar__title {
    color: #fff;
  }

  .content {
      // background: #f4f5f7;
      padding: 16px 12px;

      .list-item {
        padding: 12px 12px 6px;
        margin-bottom: 16PX;
        background: #fff;
        border-radius: 12px;
        box-shadow: 2px 0px 6px rgba(0, 0, 0, .05);

        &-user {
          background: #f2f3f7;
          padding: 10px;
          border-radius: 12px;
          font-size: 14px;
          display: flex;
          align-items: center;
          margin-bottom: 6px;

          .van-image {
            margin-right: 8px;
          }

          img {
            vertical-align: middle;
          }

          .hand {
            flex: 1;
            text-align: right;
          }
        }

        &-time {
          display: flex;
          align-items: center;
          margin-bottom: 16px;
          font-size: 12px;
          color: #999;
        }
      }

      &-item:last-child {
        margin-bottom: 0;
      }
      
  }
}
</style>
